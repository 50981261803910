import request from '../utils/request.js';
let $domain = " https://admin.bdlknl.com/api";
let $domain2 = "https://womanatd.ynlothar.com"
let $scanning = "http://127.0.0.1:38088";

export default {
	getAdsList: (data) => request.get($domain + '/home/getAdsList', data),
	
	expend: (data) => request.post($domain + '/order/expend', data),
	
	updateOrderImg: (data) => request.post($domain + '/order/updateOrderImg', data),
	
	
	
	//游客上传文件
	uploadFile: (data) => request.post($domain + '/home/uploadFile', data, false),
	//获取背景音乐
	getBgAudio:(data) => request.get($domain + '/upload/getBgAudio', data),

	
}
