
import axios from 'axios';

// 进行一些全局配置
// 公共路由(网络请求地址)
//axios.defaults.baseURL = 'https://print.ynlothar.com/api/';

axios.defaults.baseURL = ''

// 请求响应超时时间
axios.defaults.timeout = 55000;


axios.interceptors.request.use(config => {
  
 config.headers.token = window.sessionStorage.getItem('token')
 config.headers.auth = window.sessionStorage.getItem('auth')

  
  return config
})

// 封装自己的get/post方法
export default {
  get: function(path = '', data = {},istoken = true) {
    return new Promise(function(resolve, reject) {
      axios.get(path, {
        params: data,
		istoken:istoken
      })
        .then(function(response) {
          // 按需求来，这里我需要的是response.data，所以返回response.data，一般直接返回response
          resolve(response.data);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  post: function(path = '', data = {},istoken = true) {
    return new Promise(function(resolve, reject) {
      axios.post(path, data,{istoken:istoken})
        .then(function(response) {
          resolve(response.data);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  }
};
